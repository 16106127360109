import React, { Component, Fragment } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMail, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import FooterTwo from "../component/footer/FooterTwo";
import Scrollspy from "react-scrollspy";
import { FiChevronUp, FiX, FiMenu, FiPhoneCall } from "react-icons/fi";
import Breadcrumb from "../elements/common/Breadcrumb";

import AOS from "aos";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  constructor(props) {
    super(props);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };
  menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  stickyHeader = () => {};

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return (
      <Fragment>
        <PageHelmet pageTitle="Contact" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <div className="header-title" data-aos="zoom-in">
                    NUSUNM
                  </div>

                  {/* <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-light.png" alt="Logo Images"/> */}
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
              <Scrollspy
                                    className="mainmenu"
                                    items={[
                                      "home",
                                      "design",
                                      "landscape",
                                      "internal",
                                      "visualisation",
                                      "consulting",
                                      "contact",
                                  ]}
                                    currentClassName="is-current"
                                    offset={-200}
                                >
                                    <li data-aos="fade-left" data-aos-duration="1500">
                                        <a href="/">HOME</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="400"
                                    >
                                        <a href="/design">ARCHITECTURAL DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="800"
                                    >
                                        <a href="/landscape">LANDSCAPE DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1200"
                                    >
                                        <a href="/internal">INTERIOR DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1600"
                                    >
                                        <a href="/visualisation">3D VISUALISATION</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2000"
                                    >
                                        <a href="/consulting">CONSULTING</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2400"
                                    >
                                        <a href="/contact">CONTACT</a>
                                    </li>
                                </Scrollspy>
              </nav>
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"CONTACT US"} id={"contact"} />
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiPhoneCall />
                  </div>
                  <div className="inner">
                    <h4 className="title">Give us a call</h4>
                    <p>
                      <a href="tel:+61 870706160">+61 870706160</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Send us an email</h4>
                    <p>
                      <a href="mailto:info@nusunm.com">info@nusunm.com</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Come see us</h4>
                    <p>408 King William Street, Adelaide, 5000, Australia</p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        {/* <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div> */}
        {/* End Contact Page Area  */}
        <FooterTwo id="contact" />
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Contact;
