import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Creative One Page',
        description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]

class HomeParticles extends Component {
    constructor(props) {
        super(props);

        this.state = {

            dropDown: false,
        }

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    componentWillMount = async () => {
        AOS.init({
            duration: 1000
        })
    }

    dropDown = e => {
        e.preventDefault();

        this.setState({
            dropDown: true,
        })
    }
    menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader = () => { }
    render() {
        const PostList = BlogContent.slice(0, 5);

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="Home Particles" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <div className="header-title" data-aos="zoom-in">
                                        NUSUNM
                                    </div>

                                    {/* <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-light.png" alt="Logo Images"/> */}
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy
                                    className="mainmenu"
                                    items={[
                                        "home",
                                        "design",
                                        "landscape",
                                        "internal",
                                        "visualisation",
                                        "consulting",
                                        "contact",
                                    ]}
                                    currentClassName="is-current"
                                    offset={-200}
                                >
                                    <li data-aos="fade-left" data-aos-duration="1500">
                                        <a href="/">HOME</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="400"
                                    >
                                        <a href="/design">ARCHITECTURAL DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="800"
                                    >
                                        <a href="/landscape">LANDSCAPE DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1200"
                                    >
                                        <a href="/internal">INTERIOR DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1600"
                                    >
                                        <a href="/visualisation">3D VISUALISATION</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2000"
                                    >
                                        <a href="/consulting">CONSULTING</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2400"
                                    >
                                        <a href="/contact">CONTACT</a>
                                    </li>
                                </Scrollspy>
                            </nav>
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                    </div>
                    <div className="pt--20 bg_image bg_image--27">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}




                {/* Start First Pic Area */}
                <div className="about-area ptb--120 bg_color--8" id="design">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center index-title" data-aos="zoom-in-up" data-aos-duration="1500">
                                <div className="col-lg-12">
                                    <h3 className="w-100">ARCHITECTURAL DESIGN</h3>
                                </div></div>
                            <div className="row row--35 align-items-center" data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="400">

                                <div className="col-lg-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/index1.jpg" alt="Design" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End First Pic Area */}


                {/* Start landscape Area */}
                <div className="about-area ptb--120 bg_color--5" id="landscape">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5" data-aos="zoom-in-right" data-aos-duration="2500" data-aos-delay="800">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/land1.png" alt="Landscape" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title" data-aos="zoom-in-left" data-aos-duration="2500">LANDSCAPE DESIGN</h3>
                                            <p className="description" data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="400">Landscape blends architecture with nature.</p>
                                        </div>
                                        <div className="row mt--30">

                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-12 col-10">
                                            <div className="about-us-list">
                                                <div className="thumbnail">
                                                    <img data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="1200" className="w-100" src="/assets/images/land2.png" alt="Landscape" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End landscape Area */}

                {/* Start interior Area */}
                <div className="about-area ptb--120 bg_color--5" id="internal">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5" data-aos="zoom-in-right" data-aos-duration="2500" data-aos-delay="800">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/inter1.png" alt="Interior Design" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title" data-aos="zoom-in-left" data-aos-duration="2500">INTERIOR DESIGN</h3>
                                            <p className="description" data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="400">We do professional design solutions for residences, workspace, and retail environments.</p>
                                        </div>
                                        <div className="row mt--30">

                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-12 col-10">
                                            <div className="about-us-list">
                                                <div className="thumbnail">
                                                    <img data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="1200" className="w-100" src="/assets/images/inter2.png" alt="Interior Design" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End landscape Area */}

                {/* Start visualisation Area */}
                <div className="about-area ptb--120 bg_color--5" id="visualisation">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5" data-aos="zoom-in-right" data-aos-duration="2500" data-aos-delay="800">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/index2.jpg" alt="Visualisation" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title" data-aos="zoom-in-left" data-aos-duration="2500">3D VISUALISATION</h3>
                                            <p className="description" data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="400">We do professional 3D Visualisation project.</p>
                                        </div>
                                        <div className="row mt--30">
                                            {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div> */}

                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-12 col-10">
                                            <div className="about-us-list">
                                                <div className="thumbnail">
                                                    <img data-aos="zoom-in-left" data-aos-duration="2500" data-aos-delay="1200" className="w-100" src="/assets/images/index3.jpg" alt="Visualisation" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End visualisation Area */}


                {/* Start consulting Area */}
                <div className="about-area ptb--120 bg_color--8" id="consulting">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title" data-aos="zoom-out-down" data-aos-duration="2500">
                                            <h3 className="title">CONSULTING</h3>
                                            <p className="description">We do house-related consulting.</p>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="row row--35 align-items-center">
                                    <div className="col-12" data-aos="zoom-out-up" data-aos-duration="2500">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/index4.jpg" alt="Consulting" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End consulting Area */}


                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}


            </Fragment>
        )
    }
}
export default HomeParticles;