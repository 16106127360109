import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class Privacy extends Component {
    render() {
        let title = 'Privacy',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Privacy' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo-light.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Privacy'} />
                {/* End Breadcrump Area */}

                {/* Start Privacy Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        {/* <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div> */}
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Introduction</h3>
                                                    <p>This proprietary policy applies to all personal information collected by Nusunm Pty Ltd and its subsidiaries (including House QA and houseqa.com.au). By visiting the House QA website or application or using our products or services, you agree to and be bound by the terms of this policy and the terms and conditions of the House QA. This policy is also incorporated by reference into the House QA terms and conditions applicable to the products and services provided by the House QA. Collectively referred to as "our" and "us" in the policy (unless otherwise required).
<p />
                                                    By using websites and applications, subscribing to our services or agreements with us about websites and applications, you will be deemed to have read and agreed to the collection, use, disclosure and processing of your personal information. Comply with this privacy policy.
                                                    <p />
                                                    In our policy, we recognize the value of protecting personal information. We will not collect sensitive information as defined by the Privacy Act 1988 (Federal) ("Proprietary Law"), such as race or ethnic information, political opinions or associations, criminal records, or health information.
                                                    <p />
                                                    When collecting and processing personal information, we are bound by the Copyright Act (including the Australian Privacy Principles ("APPs")) and applicable discrimination laws.
                                                    <p />
                                                    We encourage all users and customers to refer to the website of the Australian Information Commissioner's Office www.oaic.gov.au, which contains important information about racial discrimination laws.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Types of personal information we collect and store</h3>
                                                    <p>The personal information we may collect and hold from you includes:
                                                    <p />
1. Identity information, such as your name, age or birthday, contact information (including address, email address, phone number or mobile phone number), occupation, license information, driver's license or passport number, user name or password; and
<p />
2. Financial details, such as your bank account details or credit card information
<p />
Without these detailed information, we may not be able to conduct business and provide you with the products or services you need or the level of services we promote.
<p />
We may also collect non-personal information about you through tracking technologies (for example, cookies, web beacons, and measurement software), including but not limited to data related to your activities on websites and applications (including IP addresses) or in connection with surveys response.
<p />
You acknowledge that the personal information you provide to us and the personal information we collect from you is your own information, or that you have been authorized to provide us with information.
<p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">How we collect and store your personal information</h3>
                                                    <p>We may collect personal information about you in a variety of ways, including contacting us by collecting records and copies of you from devices or browsers on which you can access our applications and webpages and other webpages by phone and email , You can collect GPS data through web forms, by recording your IP address, by using cookies, by recording the phone number and email address you contact us, and submitting information through any smartphone, browser plug-in or other application We use technical access that may collect information from the device or browser you use, and otherwise rely on the information available in the device and operating system you may use.
                                                    <p />
                                                    We may also obtain your personal information from our affiliates or advertising partners, customers, terminations, customers and other third parties (such as survey or competition websites, marketing websites, any other websites accessible through our website), these websites It is believed to be helpful for us to conduct advertising services, understand online activities and collect information that we believe is important for managing the quality or content of the services we provide.
                                                    <p />
                                                    Once we have collected your personal information, we may save it in electronic form or paper file form.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Why do we collect, store, use and disclose your personal information</h3>
                                                    <p>We collect, retain, use and disclose your personal information for the following purposes:
                                                    <p />
                                                    1. To perform and manage our business functions or activities, including providing you with our products or services;
                                                    <p />
                                                    2. Manage invoices and payment systems and services;
                                                    <p />
                                                    3. Manage our relationship with you as users, customers and/or shareholders;
                                                    <p />
                                                    4. Manage risks and identify or investigate illegal activities that violate our terms and conditions, including verifying your identity, ABN and/or your professional license;
                                                    <p />
                                                    5. Review, process and evaluate your application;
                                                    <p />
                                                    6. Marketing and promotion of our services and services of other entities related to us, including entities that market and promote goods and services unrelated to the goods and services we market and promote, and third-party businesses (including suppliers) Operate products or services provided by these third parties;
                                                    <p />7. Help you get approval to keep in touch with customers in specific job categories;
                                                    <p />8. Research, design, develop, manage, provide and improve our products and services;
                                                    <p />9. Contact you;
                                                    <p />10. Comply with the law, including the law regarding the handling of such litigation related to shares, share applicants and shareholders), and assist government, licensing or law enforcement agencies; and
                                                    <p />11. Adopt behaviors or practices related to the above matters.
                                                    <p />Where the law allows or requires us to do so, we may also collect, hold, use and disclose your personal information for other reasons.
                                                    <p />We reserve the right to use or disclose personal information about you, which is necessary to provide or promote or provide pedestrian services (including direct marketing via mail, telephone, email, SMS and MMS messages and any other means) Electronic means) to our users and customers, such as communications, promotional materials or direct marketing activities related to the products and services we provide. Follow the "unsubscribe" link or statement in all emails or SMS and MMS messages we send you, or by using the section below entitled "Contact Us".
                                                    <p />
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who do we disclose your personal information to</h3>
                                                    <p>Your information may be disclosed to insiders or companies for the purposes described in the "Why we collect, use and disclose your personal information" section above.
                                                    <p />We may also show your personal information to people or organizations other than vagrants for the above "Reasons for Why We Collect, Use and Display Your Personal Information" and other parties and other purposes specified below:
                                                    <p />This may include:
                                                    <p />1. The organization that manages some of our business activities and goals, including call center services, marketing, company management and company strategy;
                                                    <p />2. Service providers, including share registry service providers, IT service providers and promoters and/or promoters;
                                                    <p />3. Organizations that have a business relationship with the House of Representatives QA (such as partners, joint ventures, agents, contractors or external service providers);
                                                    <p />4. Third-party payment processing providers or debt recovery agencies related to charges or payments to provide services to passers-by users or customers;
                                                    <p />5. The police or other relevant authorities, licensing or law enforcement agencies;
                                                    <p />6. The government and regulatory agencies or organizations (such as ASX or ASIC) or laws otherwise require or authorize;
                                                    <p />7. Internet service providers or network administrators, including if we have reason to suspect that you have violated our terms and conditions, or otherwise force any illegal activity, and we reasonably believe that such disclosure is necessary.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Overseas transfer of personal information</h3>
                                                    <p>In order to provide you with the best products and services, we may transfer or disclose the personal information we collect about you to foreign individuals or organizations, including:
                                                    <p />1. To service providers located in China
                                                    <p />2. For service providers who store data outside Australia or operators outside Australia; and/or
                                                    <p />3. Other methods required or authorized by law.
                                                    <p />In doing so, we will take reasonable steps to ensure that these parties do not violate the Australian Privacy Principles.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Links</h3>
                                                    <p>We provide links to websites and third-party websites outside of the website and apps. We also allow certain third parties to display widgets and applications on our websites and applications, allowing you to interact and share content, including social links to these linked websites, applications and widgets that are not under our control, and We are not responsible for the information collected through these third-party applications or widgets through the behavior of companies linked to websites and applications. Before displaying your personal information on any other website or using these applications or widgets, you are advised to check the terms and conditions of using the website and the data collection practices and control measures of relevant third parties in their privacy policies.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Security and storage</h3>
                                                    <p>House QA attaches great importance to the security of all information related to our customers and affiliates. We have taken security measures to prevent duplication, interference, loss, and authorized access, modification or disclosure of personal information under our control.
                                                    <p />House QA will retain the information you provide to us (which may include your contact details or bank account details) so that we can verify transactions and customer details and keep adequate records for legal and accounting purposes. This information is stored on secure servers in state-of-the-art facilities.
                                                    <p />There is no guarantee that data transmission on the Internet is 100% secure. Therefore, despite our efforts to protect users’ personal information, Housing QA cannot ensure or guarantee the security of any information transmitted to it or from its online products or services, and users do so at their own risk.
                                                    <p />We note that you will be fully responsible for the confidentiality of your password and/or account information. Every time you go online, you should be cautious and take responsibility.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Access and correct personal information</h3>
                                                    <p>The House QA is responsible for maintaining correct, timely, relevant and appropriate information about our customers and website users.
                                                    <p />To gain access or seek corrections to your personal information we hold, please contact us using the details listed in the section titled "Contact Us" below. We will provide you with the right to access the information.
                                                    <p />You may ask to correct incorrect information. To ensure confidentiality, the details of your personal information will only be passed on to you when we are sure of the personal information related to you.
                                                    <p />If we refuse to provide you with access to the personal information we hold about you, we will provide a reason for the refusal.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Complaint</h3>
                                                    <p>If you have any complaints about our privacy policy or the collection, use or safe disposal or destruction of your personal information, please first send your complaint directly to us via email, e-mail: privacy@houseqa.com.au.
                                                    <p />We will investigate your complaint and try to resolve any violations of the collection, use or destruction of your personal information that we have in accordance with the laws and regulations of the Commonwealth within 30 days. You can ask an independent person (usually an information officer) to investigate your complaint.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Opting out of personalized advertising</h3>
                                                    <p>We are involved in providing you with a highly relevant experience about our website and apps. This involves our collection and use of information about the content in which you participate, such as images, articles, calculators and advertisements, as well as any information you provide to us through your houseqa.com.au account (if any). Then, we show you ads that we believe will be attractive. You can opt out of us through the exit page to personalize your advertising experience.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Changes to privacy policy</h3>
                                                    <p>If the house QA changes its "Privacy Policy", post the changes on this "Privacy Policy" page, and brake users always understand what information is collected, how to use it, and how to display the information. Policy to review any revisions.
                                                    <p />The latest update time of the privacy policy is April 3, 2021.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Contact us</h3>
                                                    <p>If you need more information about our privacy policy, please contact us in the following way: send an email to our customer operations manager: privacy@houseqa.com.au.
                                                    <p />If you want to read more about legislation or the Australian Privacy Principles, we recommend that you visit the website of the Office of the Information Commissioner at www.oaic.gov.au.
                                                    <p />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who is House QA?</h3>
                                                    <p>House QA provides a better and smarter way to contact property-related professionals and consumers so that the work can be completed smoothly.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Privacy Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Privacy