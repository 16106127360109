import React, { Component } from "react";
import { Link } from "react-router-dom";
class Breadcrumb extends Component {
  render() {
    const { title, parent, id } = this.props;
    return (
      <React.Fragment>
        <div
          className="slider-activation slider-creative-agency with-particles"
          id={id}
        >
          <div className="frame-layout__particles">
          </div>
          <div
            className="breadcrumb-area rn-bg-color ptb--105 bg_image bg_image--27"
            data-black-overlay="4"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner pt--20">
                    <h2 className="title">{title}</h2>
                    {/*} <ul className="page-list">
                      <li className="breadcrumb-item">
                        <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                      </li>
                      {parent ? (
                        <li className="breadcrumb-item">{parent}</li>
                      ) : (
                        ""
                      )}
                      <li className="breadcrumb-item active">{title}</li>
                      </ul>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Breadcrumb;
