import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "./service/ServiceList";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import Team from "./Team";
import BlogContent from "./blog/BlogContent";
import BrandTwo from "./BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "./contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import AOS from "aos";
import Breadcrumb from "./common/Breadcrumb";

class Visualisation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
    };

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };

  dropDown = (e) => {
    e.preventDefault();

    this.setState({
      dropDown: true,
    });
  };
  menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  stickyHeader = () => { };
  render() {
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Landscape Design" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <div className="header-title" data-aos="zoom-in">
                    NUSUNM
                  </div>

                  {/* <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-light.png" alt="Logo Images"/> */}
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={[
                    "home",
                    "design",
                    "landscape",
                    "internal",
                    "visualisation",
                    "consulting",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li data-aos="fade-left" data-aos-duration="1500">
                    <a href="/">HOME</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="400"
                  >
                    <a href="/design">ARCHITECTURAL DESIGN</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="800"
                  >
                    <a href="/landscape">LANDSCAPE DESIGN</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="1200"
                  >
                    <a href="/internal">INTERIOR DESIGN</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="1600"
                  >
                    <a href="/visualisation">3D VISUALISATION</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="2000"
                  >
                    <a href="/consulting">CONSULTING</a>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-delay="2400"
                  >
                    <a href="/contact">CONTACT</a>
                  </li>
                </Scrollspy>
              </nav>
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"LANDSCAPE"} id={"landscape"} />
        {/* End Breadcrump Area */}

        {/* Start visualisation Area */}
        <div className="about-area ptb--120 bg_color--5" id="landscape">
          <div className="about-wrapper">
            <div className="container">
              <div className="section-title">
                <p
                  className="description"
                  data-aos="zoom-in-left"
                  data-aos-duration="2500"
                  data-aos-delay="400"
                >
                  Landscape blends architecture with nature.
                </p>
              </div>
              <div className="row row--35 align-items-center">
                <div
                  className="col-lg-6"
                  data-aos="zoom-in-right"
                  data-aos-duration="2500"
                  data-aos-delay="800"
                >
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/land1.png"
                      alt="Visualisation"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-inner inner">
                    <div className="row mt--30">
                    </div>
                    <div>
                      <div className="about-us-list">
                        <div className="thumbnail">
                          <img
                            data-aos="zoom-in-left"
                            data-aos-duration="2500"
                            data-aos-delay="1200"
                            className="w-100"
                            src="/assets/images/land2.png"
                            alt="Visualisation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row--35 align-items-center">
                <div
                  className="col-lg-6"
                  data-aos="zoom-in-right"
                  data-aos-duration="2500"
                  data-aos-delay="800"
                >
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/land3.png"
                      alt="Visualisation"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-inner inner">
                    <div className="row mt--30">
                    </div>
                    <div>
                      <div className="about-us-list">
                        <div className="thumbnail">
                          <img
                            data-aos="zoom-in-left"
                            data-aos-duration="2500"
                            data-aos-delay="1200"
                            className="w-100"
                            src="/assets/images/land4.png"
                            alt="Visualisation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End visualisation Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Visualisation;
