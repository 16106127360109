import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import BrandTwo from "./BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";

class Privacycn extends Component {
  render() {
    let title = "Privacy",
      description =
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="隐私政策" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo-light.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"隐私政策"} />
        {/* End Breadcrump Area */}

        {/* Start Privacy Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    {/* <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div> */}
                    <div className="row mt--30">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">介绍</h3>
                          <p>
                            本隐私政策适用于Nusunm Pty Ltd及其子公司（包括House
                            QA和houseqa.com.au）收集的所有个人信息。通过访问House
                            QA网站或应用程序或使用我们的产品或服务，即表示您同意本政策的条款以及House
                            QA的条款和条件，并受其约束。本政策也通过引用并入适用于House
                            QA提供的产品和服务的House QA条款和条件。House
                            QA和houseqa.com.au在本隐私政策（除非上下文另有要求）中统称为
                            “我们的”和“我们”。
                            <p />
                            本隐私政策规定了我们收集，使用，披露和管理个人信息的方式。通过使用网站和应用，订阅我们的服务或与我们签订有关网站和应用的协议，您将被视为已阅读并同意收集，使用，披露和处理您的个人信息。遵守本隐私政策。
                            <p />
                            在本政策中，我们认识到保护个人信息的重要性。我们不会收集《
                            1988年隐私法》（联邦）（“隐私法”）所定义的敏感信息，例如种族或种族信息，政治见解或社团，犯罪记录或健康信息。
                            <p />
                            在收集和处理个人信息时，我们受《隐私法》（包括《澳大利亚隐私原则》（“
                            APPs”））和适用的隐私法规的约束。
                            <p />
                            我们鼓励所有使用者和客户参考澳大利亚信息专员办公室的网站www.oaic.gov.au，其中包含有关澳大利亚隐私法的重要信息。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">
                            我们收集和保存的个人信息的种类
                          </h3>
                          <p>
                            我们可能会从您那里收集并持有的个人信息包括：
                            <p />
                            1.身份信息，例如您的姓名，年龄或生日，联系方式（包括地址，电子邮件地址，电话号码或手机号码），职业，许可证信息，驾驶执照或护照号码，用户名或密码；和
                            <p />
                            2.财务详细信息，例如您的银行帐户详细信息或信用卡信息。
                            <p />
                            没有这些详细信息，我们可能无法开展业务，无法为您提供所需的产品或服务或我们旨在提供的服务水平。
                            <p />
                            我们还可能通过跟踪技术（例如Cookie，网络信标和测量软件）收集有关您的非个人信息，包括但不限于与您在网站和应用上的活动有关的数据（包括IP地址）或与调查答复。
                            <p />
                            您承认，您提供给我们的以及我们从您那里收集的个人信息是您自己的信息，或者您已被授权提供给我们的信息。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">
                            我们如何收集和保存您的个人信息
                          </h3>
                          <p>
                            我们可能会通过多种方式收集有关您的个人信息，包括通过电话和电子邮件从您用来访问我们的应用程序和网页的设备或浏览器以及其他网页中收集记录和您的副本的方式与我们联系，您可以通过Web表单，通过记录您的IP地址，收集GPS数据，通过使用Cookie，通过记录您与我们联系的电话号码和电子邮件地址，通过任何智能手机，浏览器插件或其他应用程序提交信息我们使用可能会从您使用的设备或浏览器中收集信息，并以其他方式依赖对您可能使用的设备和操作系统中可用信息的技术访问。
                            <p />
                            我们还可能从我们的关联公司或广告合作伙伴，客户，承包商，客户和其他第三方（例如调查或竞争网站，营销网站，可通过我们网站访问的任何其他网站）获得您的个人信息，这些网站被认为有助于我们进行广告投放服务，了解在线活动并收集我们认为对管理我们提供的服务的质量或内容很重要的信息。
                            <p />
                            一旦我们收集了您的个人信息，我们可能会将其以电子形式或纸质文件形式保存。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">
                            我们为什么收集，保存，使用和披露您的个人信息
                          </h3>
                          <p>
                            我们出于以下目的收集，保留，使用和披露您的个人信息：
                            <p />
                            1.
                            执行和管理我们的业务功能或活动，包括向您提供我们的产品或服务；
                            <p />
                            2. 管理发票和付款系统及服务；
                            <p />
                            3. 管理我们作为用户，客户和/或股东与您的关系；
                            <p />
                            4.
                            管理风险并识别或调查违反我们的条款和条件的非法活动，包括通过验证您的身份，ABN和/或您的专业许可；
                            <p />
                            5. 审查，处理和评估您的申请；
                            <p />
                            6.
                            营销和宣传我们的服务以及与我们有关系的其他实体的服务，包括营销和推广与我们营销和推广的商品和服务无关的商品和服务的实体，以及第三方业务（包括供应商）的运营这些第三方提供的产品或服务；
                            <p />
                            7. 帮助您获得批准以与特定职位类别的客户保持联系；
                            <p />
                            8.
                            研究，设计，开发，管理，提供和改善我们的产品和服务；
                            <p />
                            9. 与您联系；
                            <p />
                            10.
                            遵守法律，包括有关处理与股份，股份申请人和股东有关的事宜的法律），并协助政府，许可或执法机构；和
                            <p />
                            11. 采取与上述事项有关的行为或惯例。
                            <p />
                            在法律允许或要求我们这样做的情况下，我们还可能出于其他原因收集，持有，使用和披露您的个人信息。
                            <p />
                            我们保留使用或披露与您有关的个人信息的权利，这些信息是提供或促进或提供行人服务的信息所必需的（包括通过邮件，电话，电子邮件，SMS和MMS消息以及任何其他方式的直接营销）电子方式）给我们的用户和客户，例如通讯，促销材料或与我们提供的产品和服务相关的直接营销活动。如果您不再希望收到我们的直接营销电子邮件或SMS和MMS消息，请按照我们发送给您的所有电子邮件或SMS和MMS消息中的“取消订阅”链接或声明，或通过使用下面标题为“与我们联系”的部分。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">我们向谁透露您的个人信息</h3>
                          <p>
                            您的信息可能会出于上述“为什么我们收集，使用和披露您的个人信息”部分中所述的目的而向行内人士或公司披露。
                            <p />
                            我们还可能出于上述“为什么我们收集，使用和披露您的个人信息的原因”部分以及其他方以及以下指定的其他目的，将您的个人信息披露给流浪人士以外的人或组织：
                            <p />
                            这可能包括：
                            <p />
                            1.
                            管理我们某些业务活动和职能的组织，包括呼叫中心服务，市场营销，公司管理和公司战略；
                            <p />
                            2.
                            服务提供商，包括股份注册管理机构服务提供商，IT服务提供商以及发起人和/或发起人；
                            <p />
                            3. 与House
                            QA有业务关系的组织（例如合作伙伴，合资企业，代理商，承包商或外部服务提供商）；
                            <p />
                            4.
                            与计费或付款有关的第三方付款处理提供商或债务追回机构，以向过客用户或客户提供服务；
                            <p />
                            5. 警察或其他有关当局，许可或执法机构；
                            <p />
                            6.
                            政府和监管机构或组织（例如ASX或ASIC）或法律另有要求或授权的；
                            <p />
                            7.
                            互联网服务提供商或网络管理员，包括，如果我们有理由怀疑您违反了我们的条款和条件，或者以其他方式从事了任何非法活动，并且我们合理地认为有必要进行此类披露。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">个人信息的海外转移</h3>
                          <p>
                            为了向您提供最好的产品和服务，我们可能会将我们收集的有关您的个人信息转让或披露给外国的个人或组织，其中包括：
                            <p />
                            1. 给位于中国的服务提供商
                            <p />
                            2.
                            面向在澳大利亚以外存储数据或在澳大利亚以外运营的服务提供商；和/或
                            <p />
                            3. 法律要求或授权的其他方式。
                            <p />
                            在执行此操作时，我们将采取合理的步骤来确保这些各方不违反《澳大利亚隐私权原则》。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">链接</h3>
                          <p>
                            我们提供指向网站和应用之外的网站以及第三方网站的链接。我们还允许某些第三方在我们的网站和应用上显示窗口小部件和应用程序，使您可以进行交互和共享内容，包括社交媒体按钮，例如Facebook分享等，Twitter，Pinterest和Google+。这些链接的网站，应用程序和窗口小部件不受我们的控制，并且我们不对与网站和应用上链接的公司的行为或其通过这些第三方应用程序或窗口小部件收集的信息承担任何责任。建议您在任何其他网站或使用这些应用程序或小部件上披露您的个人信息之前，先检查使用该网站的条款和条件以及相关第三方在其隐私权政策中的数据收集惯例和控制措施。{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">安全性和存储</h3>
                          <p>
                            House
                            QA非常重视与我们的客户和承包商相关的所有信息的安全性。我们采取了安全措施，以防止滥用，干扰，丢失以及在我们控制之下的个人信息的未经授权的访问，修改或披露。
                            <p />
                            House
                            QA会保留您提供给我们的信息（可能包括您的联系方式或银行帐户详细信息），以使我们能够验证交易和客户详细信息，并保留用于法律和会计目的的充分记录。此信息保存在受控设施中的安全服务器上。
                            <p />
                            不能保证互联网上的数据传输是100％安全的。因此，尽管我们努力保护用户的个人信息，但House
                            QA无法确保或保证传输给它或来自其在线产品或服务的任何信息的安全性，用户自负风险。
                            <p />
                            我们注意到，您将对密码和/或帐户信息的保密负全部责任。每次上网时，您都应该小心谨慎并承担责任。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">访问和更正个人信息</h3>
                          <p>
                            House
                            QA致力于维护有关我们的客户和网站用户的准确，及时，相关和适当的信息。
                            <p />
                            要获得访问或寻求对我们持有的您的个人信息的更正，请使用下面标题为“与我们联系”部分中列出的详细信息与我们联系。只要您对您的个人信息的要求符合澳大利亚隐私权原则，我们将为您提供访问该信息的权限。
                            <p />
                            您可能要求纠正不正确的信息。为确保机密性，只有在我们确信与您有关的个人信息时，您的个人信息的详细信息才会传递给您。
                            <p />
                            如果我们拒绝为您提供访问权限或更正我们持有的有关您的个人信息，则我们将提供拒绝的理由。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">投诉</h3>
                          <p>
                            如果您对我们的隐私政策或收集，使用或安全处置或破坏您的个人信息有任何投诉，请首先通过电子邮件将您的投诉直接发送给我们，电子邮件为：privacy@houseqa.com.au。
                            <p />
                            我们将调查您的投诉，并尝试在30天之内解决根据英联邦隐私法规收集，使用或销毁我们所拥有的有关您的个人信息的任何违规行为。如果您对该程序的结果不满意，则可以要求由独立人士（通常是信息专员）调查您的投诉。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">选择退出个性化广告</h3>
                          <p>
                            我们旨在为您提供有关我们的网站和应用的高度相关的体验。这涉及我们收集和使用有关您参与的内容的信息，例如图像，文章，计算器和广告，以及您通过houseqa.com.au帐户（如果有）提供给我们的任何信息。然后，我们向您展示我们相信会吸引人的广告。您可以通过退出页面选择退出我们，以个性化您的广告体验。
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">隐私政策的变更</h3>
                          <p>
                            如果House
                            QA更改了其“隐私政策”，它将在此“隐私政策”页面上发布更改，以便用户始终了解收集了哪些信息，如何使用它以及如何披露信息。因此，请记住定期参考本隐私政策以审查任何修订。
                            <p />
                            隐私政策的最新更新时间为2021年4月3日。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">联络我们</h3>
                          <p>
                            如果您需要有关我们的隐私政策的更多信息，请通过以下方式与我们联系：给我们的客户运营负责人发送电子邮件：privacy@houseqa.com.au
                            。
                            <p />
                            如果您想阅读有关隐私立法或《澳大利亚隐私原则》的更多信息，我们建议您访问信息专员办公室的网站，网址为www.oaic.gov.au。
                            <p />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">谁是House QA？</h3>
                          <p>
                            House
                            QA提供了一种更好，更智能的方式来联系房产相关专业人士和消费者，并使工作顺利完成。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Privacy Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Privacycn;
