import React, { Component, Fragment } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Scrollspy from "react-scrollspy";
import AOS from "aos";

const PortfolioList = [
  {
    images: "7",
    category: "Freelancer",
    title: "The Language of Developer",
  },
  {
    images: "1",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "2",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "3",
    category: "Freelancer",
    title: "Getting tickets to the big show",
  },
  {
    images: "8",
    category: "Freelancer",
    title: "You can see your Portfolio",
  },
  {
    images: "9",
    category: "Freelancer",
    title: "Getting tickets to the big show",
  },
];

const TabOne = [
  {
    image: "index1",
    bigImage: "/assets/images/index1.jpg",
    category: "Web Design",
    title: "Design is a creative part",
  },
];

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
      tab1: 0,
      isOpen: false,
    };

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };

  dropDown = (e) => {
    e.preventDefault();

    this.setState({
      dropDown: true,
    });
  };
  menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  stickyHeader = () => {};
  render() {
    const { tab1, isOpen } = this.state;

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <PageHelmet pageTitle="Design" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <div className="header-title" data-aos="zoom-in">
                    NUSUNM
                  </div>

                  {/* <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-light.png" alt="Logo Images"/> */}
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
              <Scrollspy
                                    className="mainmenu"
                                    items={[
                                      "home",
                                      "design",
                                      "landscape",
                                      "internal",
                                      "visualisation",
                                      "consulting",
                                      "contact",
                                  ]}
                                    currentClassName="is-current"
                                    offset={-200}
                                >
                                    <li data-aos="fade-left" data-aos-duration="1500">
                                        <a href="/">HOME</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="400"
                                    >
                                        <a href="/design">ARCHITECTURAL DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="800"
                                    >
                                        <a href="/landscape">LANDSCAPE DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1200"
                                    >
                                        <a href="/internal">INTERIOR DESIGN</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="1600"
                                    >
                                        <a href="/visualisation">3D VISUALISATION</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2000"
                                    >
                                        <a href="/consulting">CONSULTING</a>
                                    </li>
                                    <li
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="2400"
                                    >
                                        <a href="/contact">CONTACT</a>
                                    </li>
                                </Scrollspy>
              </nav>
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"ARCHITECTURAL DESIGN"} id={"design"} />
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper  */}
        <main className="page-wrapper">
          {/* Start Portfolio Area  */}
          <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
            <div className="container">
              <div className="row">
                {TabOne.map((value, index) => (
                  <div className="col-lg-12" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={TabOne[tab1].bigImage}
                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                        prevSrc={
                          TabOne[(tab1 + TabOne.length - 1) % TabOne.length]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            tab1: (tab1 + 1) % TabOne.length,
                          })
                        }
                      />
                    )}
                    <div className="item-portfolio-static">
                      <div
                        onClick={() =>
                          this.setState({ isOpen: true, tab1: index })
                        }
                      >
                        <div className="portfolio-static">
                          <div className="thumbnail-inner">
                            <div className="thumbnail">
                              <a href="#portfolio-details">
                                <img
                                  src={`/assets/images/${value.image}.jpg`}
                                  alt="Design Images"
                                />
                              </a>
                            </div>
                          </div>
                          {/* <div className="content">
                            <div className="inner">
                              <p>{value.category}</p>
                              <h4>
                                <a href="#portfolio-details">{value.title}</a>
                              </h4>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Portfolio Area  */}

          {/* Start Portfolio Area  */}
          {/*  <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
            <div className="container plr--10">
              <div className="row row--5">
                {PortfolioList.map((value, i) => (
                  <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="portfolio-style--3">
                      <div className="thumbnail">
                        <a href="/portfolio-details">
                          <img
                            className="w-100"
                            src={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
                            alt="Portfolio Images"
                          />
                        </a>
                      </div>
                      <div className="content">
                        <p className="portfoliotype">{value.category}</p>
                        <h4 className="title">
                          <a href="/portfolio-details">{value.title}</a>
                        </h4>
                        <div className="portfolio-btn">
                          <a
                            className="rn-btn text-white"
                            href="/portfolio-details"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* End Portfolio Area  */}
        </main>
        {/* End Page Wrapper  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Area  */}
        <FooterTwo />
        {/* End Footer Area  */}
      </Fragment>
    );
  }
}

export default Gallery;
